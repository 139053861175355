// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-contact-us-jsx": () => import("./../../../src/pages/contact-us.jsx" /* webpackChunkName: "component---src-pages-contact-us-jsx" */),
  "component---src-pages-covid-19-policy-jsx": () => import("./../../../src/pages/covid-19-policy.jsx" /* webpackChunkName: "component---src-pages-covid-19-policy-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-message-sent-jsx": () => import("./../../../src/pages/message-sent.jsx" /* webpackChunkName: "component---src-pages-message-sent-jsx" */),
  "component---src-pages-services-jsx": () => import("./../../../src/pages/services.jsx" /* webpackChunkName: "component---src-pages-services-jsx" */),
  "component---src-pages-services-land-rover-air-conditioning-services-jsx": () => import("./../../../src/pages/services/land-rover-air-conditioning-services.jsx" /* webpackChunkName: "component---src-pages-services-land-rover-air-conditioning-services-jsx" */),
  "component---src-pages-services-land-rover-bespoke-projects-jsx": () => import("./../../../src/pages/services/land-rover-bespoke-projects.jsx" /* webpackChunkName: "component---src-pages-services-land-rover-bespoke-projects-jsx" */),
  "component---src-pages-services-land-rover-bodywork-repair-painting-jsx": () => import("./../../../src/pages/services/land-rover-bodywork-repair-painting.jsx" /* webpackChunkName: "component---src-pages-services-land-rover-bodywork-repair-painting-jsx" */),
  "component---src-pages-services-land-rover-consultation-advice-jsx": () => import("./../../../src/pages/services/land-rover-consultation-advice.jsx" /* webpackChunkName: "component---src-pages-services-land-rover-consultation-advice-jsx" */),
  "component---src-pages-services-land-rover-defender-servicing-jsx": () => import("./../../../src/pages/services/land-rover-defender-servicing.jsx" /* webpackChunkName: "component---src-pages-services-land-rover-defender-servicing-jsx" */),
  "component---src-pages-services-land-rover-diagnostics-jsx": () => import("./../../../src/pages/services/land-rover-diagnostics.jsx" /* webpackChunkName: "component---src-pages-services-land-rover-diagnostics-jsx" */),
  "component---src-pages-services-land-rover-discovery-servicing-jsx": () => import("./../../../src/pages/services/land-rover-discovery-servicing.jsx" /* webpackChunkName: "component---src-pages-services-land-rover-discovery-servicing-jsx" */),
  "component---src-pages-services-land-rover-engine-tuning-jsx": () => import("./../../../src/pages/services/land-rover-engine-tuning.jsx" /* webpackChunkName: "component---src-pages-services-land-rover-engine-tuning-jsx" */),
  "component---src-pages-services-land-rover-galvanised-chassis-fitting-jsx": () => import("./../../../src/pages/services/land-rover-galvanised-chassis-fitting.jsx" /* webpackChunkName: "component---src-pages-services-land-rover-galvanised-chassis-fitting-jsx" */),
  "component---src-pages-services-land-rover-mot-preparation-jsx": () => import("./../../../src/pages/services/land-rover-mot-preparation.jsx" /* webpackChunkName: "component---src-pages-services-land-rover-mot-preparation-jsx" */),
  "component---src-pages-services-land-rover-parts-enhancements-jsx": () => import("./../../../src/pages/services/land-rover-parts-enhancements.jsx" /* webpackChunkName: "component---src-pages-services-land-rover-parts-enhancements-jsx" */),
  "component---src-pages-services-land-rover-restoration-jsx": () => import("./../../../src/pages/services/land-rover-restoration.jsx" /* webpackChunkName: "component---src-pages-services-land-rover-restoration-jsx" */),
  "component---src-pages-services-land-rover-sales-jsx": () => import("./../../../src/pages/services/land-rover-sales.jsx" /* webpackChunkName: "component---src-pages-services-land-rover-sales-jsx" */),
  "component---src-pages-services-land-rover-tyres-jsx": () => import("./../../../src/pages/services/land-rover-tyres.jsx" /* webpackChunkName: "component---src-pages-services-land-rover-tyres-jsx" */),
  "component---src-pages-services-land-rover-welding-fabrication-jsx": () => import("./../../../src/pages/services/land-rover-welding-fabrication.jsx" /* webpackChunkName: "component---src-pages-services-land-rover-welding-fabrication-jsx" */),
  "component---src-pages-services-land-rover-wheel-alignment-jsx": () => import("./../../../src/pages/services/land-rover-wheel-alignment.jsx" /* webpackChunkName: "component---src-pages-services-land-rover-wheel-alignment-jsx" */),
  "component---src-pages-services-range-rover-restoration-jsx": () => import("./../../../src/pages/services/range-rover-restoration.jsx" /* webpackChunkName: "component---src-pages-services-range-rover-restoration-jsx" */),
  "component---src-pages-services-range-rover-servicing-jsx": () => import("./../../../src/pages/services/range-rover-servicing.jsx" /* webpackChunkName: "component---src-pages-services-range-rover-servicing-jsx" */)
}

